// @flow

import React, { Fragment } from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { v4 } from 'uuid';
import LazyLoad from 'react-lazyload';
import { ArtistListCard, ArtistListArts, ArtistListArtsPlaceholder } from '@riseart/artist';
import { MediaQuery } from '@riseart/common';
import { GridHorizontal, GridHorizontalItem } from '@riseart/grid';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import FILTER_ARTIST_ART_QUERY from 'shared_data/queries/artist/filterArt.graphql';
import { ListArtQuery } from 'shared_data/providers/queries/ListArtQuery';
import { dataToBasicArtProps } from 'shared_services/riseart/utils/artDataUtils';
import { FilterFollowsProvider } from 'shared_data/providers/follow/Follow';
import { GridNoResults } from 'shared_components/arts/containers/grid/NoResults';

import { raScreenXsMax } from '@riseart/antd-provider/dist/website/variables.less';

const PRELOADER_SUBSCRIBER_IDS = { filterArtistArt: v4() };

type Props = {
  renderFollow?: boolean,
  items: Array<Object>,
  storeCode: ?string,
  locale: Object,
  infoListRenderer?: Function,
  gridMargin?: string,
  loading?: boolean,
  noItems?: Node,
  cardTitleTag?: string,
};

/**
 * ArtistListGrid
 *
 * @param {Props} props
 * @returns {Node}
 */
export function ArtistListGrid({
  renderFollow = false,
  items,
  storeCode,
  locale,
  infoListRenderer,
  gridMargin,
  loading,
  noItems,
  cardTitleTag,
}: Props): Node {
  return (
    <GridHorizontal {...(gridMargin ? { margin: gridMargin } : null)}>
      <MediaQuery maxWidth={raScreenXsMax}>
        {(isScreenSm) => {
          const artListPlaceholder = <ArtistListArtsPlaceholder itemsCount={isScreenSm ? 3 : 5} />;
          const ProviderComponent = renderFollow ? FilterFollowsProvider : Fragment;

          return (
            <ProviderComponent>
              {loading || (items && items.length > 0) ? (
                items.map((item: Object) => {
                  const hasArtworks = Array.isArray(item.artworks);

                  return (
                    <GridHorizontalItem key={item.id}>
                      <ArtistListCard
                        isMobile={isScreenSm}
                        {...(cardTitleTag ? { titleTag: cardTitleTag } : null)}
                        name={item.name}
                        infoList={
                          typeof infoListRenderer === 'function' ? infoListRenderer(item) : null
                        }
                        url={UrlAssembler.artistProfile(item.id, item.alias, {
                          locale,
                        })}
                      >
                        {/* Do not load GQL query if artworks are provided in item data */}
                        {hasArtworks ? (
                          <ArtistListArts
                            arts={item.artworks.map((artwork) => ({
                              ...artwork,
                              images: artwork.images.map((artworkImage) => ({
                                ...artworkImage,
                                ...(artworkImage.file || {}),
                              })),
                            }))}
                            displayStyle={5}
                            isMobile={isScreenSm}
                            artPropsMapper={(item) =>
                              dataToBasicArtProps(item, locale, 'artist.listArt')
                            }
                          />
                        ) : (
                          <LazyLoad once offset={500} placeholder={artListPlaceholder}>
                            <ListArtQuery
                              query={FILTER_ARTIST_ART_QUERY}
                              variables={{
                                artistId: item.id,
                                inputArtFilter: { store: storeCode, items: 5 },
                              }}
                              dataHandler={(data) => data && data.filterArtistArt}
                              preloadSubscriberId={PRELOADER_SUBSCRIBER_IDS.filterArtistArt}
                              placeholder={artListPlaceholder}
                              skip={hasArtworks}
                            >
                              {(data) =>
                                data ? (
                                  <ArtistListArts
                                    arts={data}
                                    displayStyle={5}
                                    isMobile={isScreenSm}
                                    artPropsMapper={(item) =>
                                      dataToBasicArtProps(item, locale, 'artist.listArt')
                                    }
                                  />
                                ) : null
                              }
                            </ListArtQuery>
                          </LazyLoad>
                        )}
                      </ArtistListCard>
                    </GridHorizontalItem>
                  );
                })
              ) : (
                <GridNoResults>
                  {noItems || (
                    <FormattedMessage id="components.artist.gridNoArtistsDefault">
                      {(noItemsText: string) => noItemsText}
                    </FormattedMessage>
                  )}
                </GridNoResults>
              )}
            </ProviderComponent>
          );
        }}
      </MediaQuery>
    </GridHorizontal>
  );
}
